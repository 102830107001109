import React from 'react'
import { splitOrEmptyArr } from '../util'

export function Overlay({ visible = true, children: [front, ...back], ...props }) {
	return <div {...props}
		className={[
			'civnow-overlay-outer',
			visible ? 'civnow-overlay-visible' : 'civnow-overlay-invisible',
			...splitOrEmptyArr(props.className)
		].join(' ')}
	>
		<div className='civnow-overlay-front'>
			{visible ? front : null}
		</div>
		<div className='civnow-overlay-back'>
			{back}
		</div>
	</div>
}

export default Overlay
