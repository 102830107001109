import React, { useState } from 'react'
import {
	Button,
	InlineLoading,
	InlineNotification,
} from 'carbon-components-react'

import { useApiWithEvents } from '../ApiContext'

export function LoginButton() {
	const { session } = useApiWithEvents('session')
	const { sessionStatus } = session
	return <React.Fragment>
		<Button
			disabled={!!sessionStatus.waiting}
			renderIcon={sessionStatus.waiting ? InlineLoading : undefined}
			onClick={() => document.location = session.prepareOAuthLoginUrl()}
		>
			<span><strong>Log in</strong> with your Discord&nbsp;account</span>
		</Button>
		{sessionStatus.error && <InlineNotification lowContrast hideCloseButton
			kind="error"
			title={`Login failed.`}
			subtitle={`${sessionStatus.error}`}
		// actions={<NotificationActionButton onClick={() => retryLogin()}>
		// 	Retry</NotificationActionButton>}
		/>}
	</React.Fragment>
}

export function LogoutButton() {
	const { session } = useApiWithEvents('session')
	const [inProgress, setInProgress] = useState(false)
	if (!session.sessionToken) {
		return <Button disabled kind='secondary'>Not logged in</Button>
	}
	return <Button kind='secondary'
		disabled={inProgress}
		renderIcon={inProgress ? InlineLoading : undefined}
		onClick={() => {
			setInProgress(true)
			session.logout()
				.then(() => setInProgress(false))
		}}
	>
		<span>Log out</span>
	</Button>
}
