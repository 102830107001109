import React, { useMemo } from 'react'

import { TooltipWithBounds, useTooltip } from '@vx/tooltip'

import { adjustWeekdayNr, monthNamesBase0, msAdjusted, weekdayNameFromNr, weekdayNames } from '../../api/time'
import { leftPad } from '../../api/util'
import { cssFromHSL, KeyValList } from '../util'

/** @typedef {{d:any, dateAdj:Date, dayInMonthBase1:number, weekInMonth:number, weekdayNrAdj:number}} DayStat */
/** @typedef {{
 * year:number, monthBase0:number,
 * weekMin:number, weekMax:number,
 * days:{[dayInMonthBase1:number]:DayStat},
 * }} YearMonthStat */

const noData = []

export function CalendarChart({
	data,
	getDate = d => +d.ts,

	weekBegin = 0, // number of the first day of the week. 0 = Sunday

	getHue = d => .3,
	getSaturation = d => .5,
	getLightness = d => .5,
	getOpacity = d => 1,
	getTooltip = KeyValList,
	onClick = undefined,

	spacing = 4,
	cellSize = 20,
	fontSize = 14,

	...extraProps
}) {
	data = data || noData

	const yearMonths = useMemo(() => {
		/** @type {{[k:string]:YearMonthStat}} */
		const yearMonths = {}
		for (const d of data) {
			const dateAdj = new Date(msAdjusted(getDate(d)))
			const year = dateAdj.getUTCFullYear()
			const monthBase0 = dateAdj.getUTCMonth()
			const dayInMonthBase1 = dateAdj.getUTCDate()
			const dayInMonthBase0 = dayInMonthBase1 - 1

			const weekdayNrFirstOfMonth = new Date(Date.UTC(year, monthBase0, 1)).getUTCDay()
			const weekdayNrAdjFirstOfMonth = adjustWeekdayNr(weekdayNrFirstOfMonth, weekBegin)
			const weekInMonth = Math.floor((dayInMonthBase0 + weekdayNrAdjFirstOfMonth) / 7)
			const weekdayNrAdj = (dayInMonthBase0 + weekdayNrAdjFirstOfMonth) % 7

			const yearMonthKey = `${year}-${leftPad(monthBase0 + 1, '00')}` // we only accept years 1970-9999 anyway
			const yearMonth = yearMonths[yearMonthKey] || (
				yearMonths[yearMonthKey] = {
					year, monthBase0,
					weekMin: weekInMonth, weekMax: weekInMonth,
					days: {},
				})
			yearMonth.weekMin = Math.min(weekInMonth, yearMonth.weekMin)
			yearMonth.weekMax = Math.max(weekInMonth, yearMonth.weekMax)
			yearMonth.days[dayInMonthBase1] = { d, dateAdj, dayInMonthBase1, weekInMonth, weekdayNrAdj }
		}
		return yearMonths
	}, [data, getDate, weekBegin])

	const {
		tooltipOpen,
		tooltipData,
		tooltipLeft,
		tooltipTop,
		hideTooltip,
		showTooltip,
	} = useTooltip()

	const height = spacing + fontSize + spacing + 7 * (cellSize + spacing)
	return <div style={{ minHeight: `${height}px`, width: '100%' }}>
		{Object.entries(yearMonths).sort().map(([ymk, monthData], i) =>
			<MonthChart key={ymk} {...{
				monthData,
				weekBegin,
				getHue, getSaturation, getLightness, getOpacity,
				onClick,
				getTooltip,
				cellSize, spacing, fontSize,
				hideTooltip, showTooltip,
				isFirst: i === 0,
			}} />
		)}
		{tooltipOpen && tooltipData && (
			<TooltipWithBounds
				style={{ position: 'fixed', backgroundColor: 'white', padding: '.5rem' }}
				top={tooltipTop} left={tooltipLeft}
			>{tooltipData}</TooltipWithBounds>
		)}
	</div>
}

/** @param {{
 * monthData:YearMonthStat,
 * }} params */
function MonthChart({
	monthData: { year, monthBase0, weekMin, weekMax, days },
	weekBegin,
	getHue, getSaturation, getLightness, getOpacity,
	onClick,
	getTooltip,
	cellSize, spacing, fontSize,
	hideTooltip, showTooltip,
	isFirst, // determines whether space is inserted on the left (instead of right) in case the chart width is smaller than the title width
}) {
	const numWeeks = 1 + weekMax - weekMin
	const topMargin = spacing + fontSize * 1.3 + spacing
	const height = topMargin + 7 * (cellSize + spacing)
	let leftMargin = spacing + cellSize + spacing
	let width = leftMargin + numWeeks * (cellSize + spacing)
	// make sure there's enough space for the title
	if (numWeeks < 2) {
		leftMargin = isFirst ? spacing + 2 * (cellSize + spacing) : spacing + cellSize + spacing
		width = spacing + 3 * (cellSize + spacing)
	}
	return <svg width={width} height={height}>
		<g transform={`translate(${leftMargin},${topMargin})`}>
			{Object.values(days).map(dayData => {
				const { d, dayInMonthBase1, weekInMonth, weekdayNrAdj } = dayData
				const x = (weekInMonth - weekMin) * (cellSize + spacing)
				const y = weekdayNrAdj * (cellSize + spacing)
				return <g
					key={dayInMonthBase1}
					onClick={onClick && (event => onClick(d, event))}
					onMouseEnter={event => {
						const { right, bottom } = event.target.getBoundingClientRect()
						const options = {
							tooltipLeft: right,
							tooltipTop: bottom,
							tooltipData: getTooltip(d, dayData),
						}
						showTooltip(options)
					}}
					onMouseLeave={() => hideTooltip()}
				>
					<rect
						x={x} y={y}
						width={cellSize} height={cellSize}
						fill={cssFromHSL(getHue(d), getSaturation(d), getLightness(d))}
						fillOpacity={getOpacity(d)}
					/>
					<text
						x={x} y={y}
						dx={cellSize / 2}
						textAnchor='middle'
						dy={(cellSize - fontSize * 1.3) / 2 + fontSize} // center vertically
						fontSize={fontSize}
						fill={(1 - getLightness(d)) * getOpacity(d) < .4 ? 'black' : 'white'}
						fillOpacity={.8}
					>{dayInMonthBase1}</text>
				</g>
			})}
			{weekdayNames.map((_, i) => {
				const weekdayName = weekdayNameFromNr(i, weekBegin)
				return <text key={weekdayName}
					x={-spacing - cellSize}
					textAnchor='start'
					y={i * (cellSize + spacing)}
					dy={(cellSize - fontSize * 1.3) / 2 + fontSize} // center vertically
					fontSize={fontSize}
					fill='#222'
				>{weekdayName.slice(0, 2)}</text>
			})}
		</g>
		<text
			x={width / 2}
			textAnchor='middle'
			y={spacing + fontSize}
			fontSize={fontSize}
			fill='#222'
		>{monthNamesBase0[monthBase0]} {year}</text>
	</svg>
}

export default CalendarChart
