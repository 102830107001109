import {
	Header,
	HeaderGlobalAction,
	HeaderGlobalBar,
	HeaderMenu,
	HeaderMenuButton,
	HeaderMenuItem,
	HeaderName,
	HeaderNavigation,
	HeaderSideNavItems,
	SideNav,
	SideNavItems,
	SideNavLink,
	SkipToContent,
} from 'carbon-components-react/lib/components/UIShell'
import { Launch16, UserAvatar20 } from '@carbon/icons-react'
import React, { useState } from 'react'
import {
	Link,
	useHistory,
} from 'react-router-dom'

import AppContentSwitch from './AppContentSwitch'
import { ExLink } from './util'

const MainNavItems = React.forwardRef(() => <React.Fragment>
	<HeaderMenuItem element={Link} to="/players">Players</HeaderMenuItem>
	<HeaderMenu menuLinkName="Global" aria-label="Global">
		<HeaderMenuItem element={Link} to="/mc/tps">TPS</HeaderMenuItem>
		<HeaderMenuItem element={Link} to="/mc/votes">Votes</HeaderMenuItem>
	</HeaderMenu>
</React.Fragment>)

const AppShell = () => {
	const [isSideNavExpanded, setSideNavExpanded] = useState(false)
	const history = useHistory()
	return <React.Fragment>
		<Header aria-label="Civ Now"
			onClick={isSideNavExpanded ? evt => {
				if (evt.target.closest('.bx--side-nav__menu, .bx--header__menu')
					|| !evt.target.closest('.bx--side-nav__submenu, .bx--header__submenu')
				) {
					evt.target.blur() // unfocus, so the sidebar closes
					evt.target.parentElement.blur() // carbon manually focuses the parent, so we need to unfocus it too
					setSideNavExpanded(false)
				} // else: target is a submenu toggle; keep sidebar open
			} : null}
		>
			<SkipToContent />
			<HeaderMenuButton
				aria-label="Open menu"
				isActive={isSideNavExpanded}
				onClick={() => setSideNavExpanded(s => !s)}
			/>
			<HeaderName element={Link} to="/" prefix="Civ">Now</HeaderName>
			<SideNav expanded={isSideNavExpanded} aria-label="Side navigation">
				<SideNavItems>

					<HeaderSideNavItems hasDivider={true}>
						<MainNavItems />
					</HeaderSideNavItems>

					<SideNavLink element={Link} to="/about">About</SideNavLink>
					<SideNavLink element={ExLink} href="https://discord.gg/9fWjvaj">
						Join the Discord <Launch16 /></SideNavLink>

				</SideNavItems>
			</SideNav>
			<HeaderNavigation aria-label="Civ Now">
				<MainNavItems />
			</HeaderNavigation>
			<HeaderGlobalBar>
				<HeaderGlobalAction aria-label="User Profile"
					onClick={() => history.push('/me')} // TODO toggle dropdown instead
				><UserAvatar20 /></HeaderGlobalAction>
			</HeaderGlobalBar>
		</Header>
		<div className='civnow-content-container'>
			<div className='civnow-content-scroll'>
				<AppContentSwitch />
			</div>
		</div>
	</React.Fragment>
}

export default AppShell
