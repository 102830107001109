export const pluralize = (s, n, suffix = 's') => n === 1 ? s : s + suffix

export function leftPad(str, mask = '00') {
	str = String(str)
	return mask.substr(0, mask.length - str.length) + str
}

export const numKeys = o => Object.keys(o || {}).length

export const dateOrNothing = ts => ts ? new Date(ts) : ts

export const lastIn = list => list[list.length - 1]

// export const argmax = (rows, fn = d => d) => rows.reduceRight((iMax, d, i) => fn(d) > fn(rows[iMax]) ? i : iMax, rows.length - 1)
export function argmax(rows, fn = d => d) {
	let iMax = rows.length - 1
	let valMax = fn(rows[iMax])
	for (let i = rows.length - 1; i >= 0; --i) {
		const val = fn(rows[i])
		if (valMax < val) {
			valMax = val
			iMax = i
		}
	}
	return iMax
}

/**
 * Calls `expunge` once `getExpiration()` lies in the past.
 * `getExpiration()` is called again before expunging.
 */
export const onExpiration = (getExpiration, expunge) => setTimeout(() => {
	if (!(getExpiration() > Date.now())) expunge()
}, getExpiration() - Date.now())

export function generateRandomString(n, chars) {
	const indices = window.crypto && window.crypto.getRandomValues
		? Array.from(window.crypto.getRandomValues(new Uint8Array(n)))
		: Array(n).fill(null).map(() => Math.floor(chars.length * Math.random()))
	return indices.map(i => chars[i % chars.length]).join('')
}

export const getLocalStorageJson = (key) => JSON.parse(window.localStorage.getItem(key) || '{}')
