import React, { useCallback } from 'react'
import { scaleLinear } from '@vx/scale'

import { useApiPath } from '../ApiContext'
import CalendarChart from '../charting/CalendarChart'
import WithLoader from '../common/WithLoader'

export const PlayerCountCalendar = () => {
	let { data, error, refetch } =
		useApiPath(`/accounts/unique/day`, useCallback(
			rows => rows && rows.map(d => ({
				...d,
				day: new Date(d.day),
				numAccounts: +d.numAccounts,
			})), []))

	const weightMin = data && Math.min(...data.map(d => +d.numAccounts))
	const weightMax = data && Math.max(...data.map(d => +d.numAccounts))
	const lightnessScale = scaleLinear({
		domain: [weightMin, weightMax],
		range: [.9, .3],
	})
	const hueScale = scaleLinear({
		domain: [weightMin, weightMax],
		range: [3 / 6, 4 / 6],
	})

	return <WithLoader name='player counts' data={data} error={error} refetch={refetch}>
		<CalendarChart
			data={data}
			getDate={useCallback(d => d.day, [])}
			weekBegin={3}
			getLightness={d => lightnessScale(+d.numAccounts)}
			getHue={d => hueScale(+d.numAccounts)}
			getTooltip={({ day, numAccounts }) => <div>
				{numAccounts} accounts on {new Date(day).toLocaleDateString()}
			</div>}
		/>
	</WithLoader>
}

export default PlayerCountCalendar
