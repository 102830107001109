import React, { useMemo } from 'react'

import { AxisBottom, AxisLeft } from '@vx/axis'
import { scaleLinear, scaleBand } from '@vx/scale'
import { TooltipWithBounds, useTooltip } from '@vx/tooltip'

import { cssFromHSL, KeyValList } from '../util'

const conditionalFlip = (flip, [a, b]) => flip ? [b, a] : [a, b]

const noData = []

export function HeatMap({
	// default size matches class civnow-content-maxwidth at 2:1 width:height
	width = 736,
	height = 368,

	data,
	getX = d => +d.x,
	getY = d => +d.y,
	// ordered list of x/y values if x/y are not numeric
	categoriesX = undefined,
	categoriesY = undefined,
	// domain; calculated from data by default
	minX: minXParam = undefined,
	maxX: maxXParam = undefined,
	intervalX: intervalXParam = undefined,
	minY: minYParam = undefined,
	maxY: maxYParam = undefined,
	intervalY: intervalYParam = undefined,

	topToBottomY = undefined, // default bottom to top, if categoriesY then default top to bottom
	centerXTicks = undefined, // default false, if categoriesX then default true
	centerYTicks = undefined, // default false, if categoriesY then default true

	getHue = d => .3,
	getSaturation = d => .5,
	getLightness = d => .5,
	getOpacity = d => 1,
	getTooltip = KeyValList,
	onClick = undefined,

	title = undefined,
	// axis labels
	labelX = undefined,
	labelY = undefined,

	cellSpacing = 4,
	margin = {
		top: 32,
		right: 16,
		bottom: 64,
		left: 64,
	},
	axisProps = { fontSize: 14, fill: '#222' },

	...extraProps
}) {
	data = data || noData

	const rangeX = width - margin.left - margin.right
	const rangeY = height - margin.top - margin.bottom

	if (topToBottomY === undefined) topToBottomY = categoriesY ? true : false
	if (centerXTicks === undefined) centerXTicks = categoriesX ? true : false
	if (centerYTicks === undefined) centerYTicks = categoriesY ? true : false

	const memX = useMemo(() => {
		if (categoriesX) {
			return { numX: categoriesX.length }
		} else {
			const allX = data.map(d => getX(d)).sort((a, b) => a - b)
			const minX = minXParam !== undefined ? minXParam : Math.min(...allX)
			const maxX = maxXParam !== undefined ? maxXParam : Math.max(...allX)
			const intervalX = (typeof intervalXParam === 'number') ? intervalXParam
				: Math.min(...allX.map((X, i) => X - allX[i - 1]).filter(dX => dX))
			const numX = 1 + (maxX - minX) / intervalX
			return { numX, minX, maxX, intervalX }
		}
	}, [categoriesX, data, getX, intervalXParam, maxXParam, minXParam])

	const memY = useMemo(() => {
		if (categoriesY) {
			return { numY: categoriesY.length }
		} else {
			const allY = data.map(d => getY(d)).sort((a, b) => a - b)
			const minY = minYParam !== undefined ? minYParam : Math.min(...allY)
			const maxY = maxYParam !== undefined ? maxYParam : Math.max(...allY)
			const intervalY = (typeof intervalYParam === 'number') ? intervalYParam
				: Math.min(...allY.map((y, i) => y - allY[i - 1]).filter(dy => dy))
			const numY = 1 + (maxY - minY) / intervalY
			return { numY, minY, maxY, intervalY }
		}
	}, [categoriesY, data, getY, intervalYParam, maxYParam, minYParam])

	const memScale = useMemo(() => {
		const cellIntervalScreenX = (rangeX - cellSpacing) / memX.numX
		const scaleX = categoriesX
			? scaleBand({
				domain: categoriesX,
				range: [0, rangeX],
				paddingInner: cellSpacing / (rangeX - cellSpacing),
			})
			: scaleLinear({
				domain: [memX.minX, memX.maxX + (centerXTicks ? 0 : memX.intervalX)],
				range: [0, rangeX],
			})

		const cellIntervalScreenY = (rangeY - cellSpacing) / memY.numY
		const scaleY = categoriesY
			? scaleBand({
				domain: categoriesY,
				range: conditionalFlip(!topToBottomY, [0, rangeY]),
				paddingInner: cellSpacing / (rangeY - cellSpacing),
			})
			: scaleLinear({
				domain: [memY.minY, memY.maxY + (centerYTicks ? 0 : memY.intervalY)],
				range: conditionalFlip(!topToBottomY, [0, rangeY - cellIntervalScreenY]),
			})
		return {
			cellIntervalScreenX, cellIntervalScreenY,
			scaleX, scaleY,
		}
	}, [
		categoriesX, categoriesY,
		cellSpacing,
		centerXTicks, centerYTicks,
		memX, memY,
		rangeX, rangeY,
		topToBottomY,
	])

	const cellWidth = memScale.cellIntervalScreenX - cellSpacing
	const cellHeight = memScale.cellIntervalScreenY - cellSpacing

	const {
		tooltipOpen,
		tooltipData,
		tooltipLeft,
		tooltipTop,
		hideTooltip,
		showTooltip,
	} = useTooltip()

	return <div>
		<svg {...extraProps} viewBox={`0 0 ${width} ${height}`}>
			<g transform={`translate(${margin.left},${margin.top})`}>
				{data.map(d => {
					const x = memScale.scaleX(getX(d)) + cellSpacing / 2
					const y = memScale.scaleY(getY(d)) + cellSpacing / 2
					return <rect
						key={`${getX(d)} ${getY(d)}`}
						x={x} y={y} width={cellWidth} height={cellHeight}
						fill={cssFromHSL(getHue(d), getSaturation(d), getLightness(d))}
						fillOpacity={getOpacity(d)}
						onClick={onClick && (event => onClick(d, event))}
						onMouseEnter={event => {
							const { left, right, top, bottom } = event.target.getBoundingClientRect()
							const options = {
								tooltipLeft: x < rangeX / 2 ? right : left,
								tooltipTop: y < rangeY / 2 ? bottom : top,
								tooltipData: getTooltip(d),
							}
							showTooltip(options)
						}}
						onMouseLeave={() => hideTooltip()}
					/>
				})}
				<AxisBottom
					scale={memScale.scaleX}
					label={labelX}
					top={rangeY + cellSpacing / 2 + 1}
					left={centerXTicks ? (memScale.cellIntervalScreenX + cellSpacing) / 2 : 0}
					tickLabelProps={() => ({ ...axisProps, textAnchor: 'middle' })}
					labelProps={{ ...axisProps, textAnchor: 'middle', dy: '.25em' }}
				/>
				<AxisLeft
					scale={memScale.scaleY}
					label={labelY}
					left={-cellSpacing / 2 - 1}
					top={categoriesY ? 0 : centerYTicks ? (memScale.cellIntervalScreenY + cellSpacing) / 2 : 0}
					tickLabelProps={() => ({ ...axisProps, textAnchor: 'end', dx: '-0.25em', dy: '0.25em' })}
					labelProps={{ ...axisProps, textAnchor: 'middle' }}
				/>
			</g>
			{title && <text
				{...axisProps}
				x={margin.left + rangeX / 2} textAnchor='middle'
				y={0} dy='1.1em'
			>{title}</text>}
		</svg>
		{tooltipOpen && tooltipData && (
			<TooltipWithBounds
				style={{ position: 'fixed', backgroundColor: 'white', padding: '.5rem' }}
				top={tooltipTop} left={tooltipLeft}
			>{tooltipData}</TooltipWithBounds>
		)}
	</div>
}

export default HeatMap
