import { lastIn, pluralize } from './util'

export const secondMs = 1000
export const minuteMs = 60 * secondMs
export const hourMs = 60 * minuteMs
export const dayMs = 24 * hourMs
export const weekMs = 7 * dayMs
export const monthMs = 30 * dayMs
/** for timezone where server restarts at 12AM */
export const serverRestartTimeZoneOffsetMs = -8 * hourMs

/** ms since epoch in server timezone, so we can use Date.getUTC... */
export const msAdjusted = d => +new Date(d) + serverRestartTimeZoneOffsetMs

export const monthNamesBase0 = 'Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec'.split(' ')

export const weekdayNames = 'Sun Mon Tue Wed Thu Fri Sat'.split(' ')
// weekBegin: number of the first day of the week. 0 = Sunday
export const weekdayNameFromNr = (nr, weekBegin = 0) => weekdayNames[adjustWeekdayNr(nr, -weekBegin)]
export const adjustWeekdayNr = (weekdayNr, weekBegin = 0) => (weekdayNr + 7 - weekBegin) % 7
export const weekdayNamesBaseWed = 'Wed Thu Fri Sat Sun Mon Tue'.split(' ')

export const buildGetBucketTs = bucketMs => d => Math.floor(((+(new Date(d))) + serverRestartTimeZoneOffsetMs) / bucketMs) * bucketMs - serverRestartTimeZoneOffsetMs

export const timeResolutions = [
	{ name: 'hour', ms: hourMs },
	// { name: 'six-hour period', ms: hourMs * 6 },
	// { name: 'half day', ms: dayMs / 2 },
	{ name: 'day', ms: dayMs },
	{ name: 'week', ms: weekMs },
	{ name: 'month', ms: monthMs },
].map(b => ({ ...b, getBucketTs: buildGetBucketTs(b.ms) }))

export function getTimeResolution(data, { maxResolution = 50, getTs = d => d.ts } = {}) {
	const timespanMs = getTs(lastIn(data)) - getTs(data[0])
	for (const timeRes of timeResolutions) {
		if (timespanMs / timeRes.ms <= maxResolution)
			return timeRes
	}
	return lastIn(timeResolutions)
}

export function formatAgeSimpleAbs(ms) {
	if (typeof ms !== 'number') return String(ms)
	if (!isFinite(ms)) return 'an eternity'
	ms = Math.abs(ms)
	const seconds = Math.floor(ms / 1000)
	if (seconds < 60) return pluralize(`${seconds} second`, seconds)
	const minutes = Math.floor(seconds / 60)
	if (minutes < 5) return `${minutes}min ${seconds - 60 * minutes}s`
	if (minutes < 60) return pluralize(`${minutes} minute`, minutes)
	const hours = Math.floor(minutes / 60)
	if (hours < 3) return `${hours}h ${minutes - 60 * hours}min`
	if (hours < 24) return pluralize(`${hours} hour`, hours)
	const days = Math.floor(hours / 24)
	if (days < 2) {
		const hoursRem = hours - 24 * days
		return pluralize(`${days} day`, days) + ` ${hoursRem}h`
	}
	if (days < 7) return pluralize(`${days} day`, days)
	const weeks = Math.floor(days / 7)
	if (weeks < 7) return pluralize(`${weeks} week`, weeks)
	// we just need an approximation here, this format is very imprecise anyway
	const months = Math.floor(days / 31)
	if (months < 7) return pluralize(`${months} month`, months)
	const years = Math.floor(days / 365)
	if (years < 7) return pluralize(`${years} year`, years)
}
