import React from 'react'
import {
	Route,
	Switch,
	useLocation,
} from 'react-router-dom'

import AboutPage from './pages/AboutPage'
import LandingPage from './pages/LandingPage'
import PlayerInfoPage from './pages/PlayerInfoPage'
import PlayersPage from './pages/PlayersPage'
import UserPage from './pages/UserPage'
import { Muted } from './util'

export const AppContentSwitch = () => <Switch>
	<Route path="/about" exact>
		<AboutPage />
	</Route>
	<Route path="/me" exact>
		<UserPage />
	</Route>
	<Route path="/player/:account">
		<PlayerInfoPage />
	</Route>
	<Route path="/players" exact>
		<PlayersPage />
	</Route>
	<Route path="/" exact>
		<LandingPage />
	</Route>
	<Route path="/" /* fallback */>
		<div className='civnow-content-maxwidth civnow-prose'>
			<h1>Soon&trade;</h1>
			<Muted>{'' + useLocation().pathname}</Muted>
		</div>
	</Route>
</Switch>

export default AppContentSwitch
