import React from 'react'
import {
	InlineLoading,
	InlineNotification,
	NotificationActionButton,
} from 'carbon-components-react'

const WithLoader = ({ name, data, error, refetch, children }) => (
	<div style={{ position: 'relative' }}>
		<div style={{ position: 'absolute', top: 0, right: 0 }}>
			{error ? <InlineNotification kind="error" lowContrast hideCloseButton
				title={`Error while loading ${name}.`}
				subtitle={`${error}`}
				actions={<NotificationActionButton onClick={refetch}>
					Retry</NotificationActionButton>}
			/>
				: !data ? <InlineLoading description={`Loading ${name} ...`} />
					: null
			}
		</div>
		{children}
	</div>
)

export default WithLoader
