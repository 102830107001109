import React from 'react'

import { useApiWithEvents } from '../ApiContext'
import { Overlay } from '../common/Overlay'
import { LoginButton, LogoutButton } from '../common/Session'

import './UserPage.scss'

export const UserPage = () => {
	const { session } = useApiWithEvents('session')

	return <Overlay visible={!session.discordUserData}>
		<div className='civnow-center-xy civnow-fullheight'>
			<div style={{ padding: '2rem', backgroundColor: 'white' }}>
				<h3>Get personal access</h3>
				<p></p>
				<LoginButton />
			</div>
		</div>
		<UserPageContent />
	</Overlay>
}

export const UserPageContent = () => {
	const { session } = useApiWithEvents('session')
	const dcUser = session.discordUserData || dcUserFakeData

	const avatarUrl = dcUser.avatar ? `https://cdn.discordapp.com/avatars/${dcUser.id}/${dcUser.avatar}.png`
		: 'https://discord.com/assets/6debd47ed13483642cf09e832ed0bc1b.png'

	return <div className='civnow-content-maxwidth civnow-prose'>
		<img src={avatarUrl} alt={`User avatar`} className='civnow-userpage-avatar' />
		<h2>Logged in as <strong>{dcUser.username}#{dcUser.discriminator}</strong></h2>
		<p>
			Soon&trade; you will be able to control settings and see private info.
		</p>
		<LogoutButton />
	</div>
}

const dcUserFakeData = {
	username: 'Username',
	discriminator: '1234',
	id: null,
	avatar: null,
}

export default UserPage
