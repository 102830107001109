import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

/** @type {React.Context<import('../api/Api').Api>} */
export const ApiContext = React.createContext(null)

export default ApiContext

/** @returns {import('../api/Api').Api} */
export function useApiWithEvents(...evtNames) {
	const api = useContext(ApiContext)
	// eslint-disable-next-line no-unused-vars
	const [_, setState] = useState(1)
	useEffect(() => {
		return api.onChange(evtName => {
			if (evtNames && !evtNames.includes(evtName)) return
			setState(s => s + 1)
		})
	}, [api, evtNames])
	return api
}

const emptyResponse = {}

export function useApiPath(path, convertData = null) {
	const api = useContext(ApiContext)
	const [response, setResponse] = useState(emptyResponse)

	const fetchPath = useCallback((bypassCache = false) => {
		setResponse(emptyResponse)
		api.fetchCached(path, bypassCache).then(apiResponse => {
			setResponse(apiResponse || emptyResponse)
		}).catch(error => setResponse({ error }))
	}, [api, path])

	useEffect(fetchPath, [api, path])

	const data = useMemo(() => {
		return convertData
			? convertData(response.data, response) || response.data
			: response.data
	}, [response, convertData])

	return {
		response,
		data,
		error: response.error,
		refetch: () => fetchPath(true),
	}
}
