import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	ClickableTile,
	Search,
} from 'carbon-components-react'

import { useApiPath } from '../ApiContext'
import { formatAgeSimpleAbs } from '../../api/time'
import { Muted } from '../util'
import WithLoader from '../common/WithLoader'

import './PlayersPage.scss'

export const PlayersPage = () => {
	const { data: accountInfos, error: accountsError, refetch: refetchAccounts } =
		useApiPath(`accounts`, rows => rows && rows
			.map(r => ({
				...r,
				login: !r.login ? undefined : new Date(r.login),
				logout: !r.logout ? undefined : new Date(r.logout),
			}))
			.sort((a, b) => {
				const chooseA = -1, chooseB = 1
				if (!a.login && !b.login) return a.account.toLowerCase() < b.account.toLowerCase() ? chooseA : chooseB
				if (!a.login) return chooseB
				if (!b.login) return chooseA
				if (a.logout && b.logout) return a.logout > b.logout ? chooseA : chooseB // both offline
				if (!a.logout && !b.logout) return a.login > b.login ? chooseA : chooseB // both online
				return !a.logout ? chooseA : chooseB // either a or b must be online
			})
		)

	const [searchQuery, setSearchQuery] = useState('')
	const searchResults = useMemo(() => {
		if (!accountInfos) return []
		const searchQueryLower = searchQuery.toLowerCase()
		return accountInfos.filter(({ account }) =>
			account.toLowerCase().includes(searchQueryLower))
	}, [searchQuery, accountInfos])

	return <div className='civnow-content-maxwidth civnow-prose'>
		<h1>Players</h1>
		<Search
			placeHolderText='Search accounts'
			labelText='Search accounts'
			defaultValue={searchQuery}
			onChange={e => setSearchQuery(e.target.value || '')}
		/>
		<WithLoader name='accounts' data={accountInfos} error={accountsError} refetch={refetchAccounts}>
			{!accountInfos ? null :
				searchResults.length
					? <PlayerCards playerInfos={searchResults} />
					: <p>No accounts match "{searchQuery.toLowerCase()}"</p>
			}
		</WithLoader>
	</div>
}

const PlayerCards = ({ playerInfos = [], maxVisible = 84 }) => {
	const history = useHistory()
	const now = Date.now()
	return <React.Fragment>
		{playerInfos.slice(0, maxVisible).map(({ account, uuid, login, logout }) =>
			<ClickableTile className='civnow-player-card'
				key={uuid || account}
				// can't wrap this in a <Link>, can't tell it to be a Link; so we emulate a Link as best as we can
				href={`/player/${account}`} // allow opening in other tab, copying url, etc.
				handleClick={evt => {
					evt.preventDefault() // don't reload page
					history.push(`/player/${account}`)
				}}
			>
				<img src={`https://minotar.net/armor/bust/${uuid || account}.png`} alt=''
					className='civnow-player-card-avatar' />
				<div className='civnow-player-card-info'>
					{account}
					<br />
					<Muted>{logout ? 'Offline ' + formatAgeSimpleAbs(now - logout)
						: login ? 'Playing ' + formatAgeSimpleAbs(now - login)
							: 'Offline'
					}</Muted>
				</div>
			</ClickableTile>
		)}
		<div className='civnow-clearfix'></div>
		{(playerInfos.length > maxVisible) && <p>
			... and {playerInfos.length - maxVisible} more ({playerInfos.length} total)
		</p>}
	</React.Fragment>
}

export default PlayersPage
