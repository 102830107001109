import React, { useCallback } from 'react'
import { scaleLinear } from '@vx/scale'

import { useApiPath } from '../ApiContext'
import CalendarChart from '../charting/CalendarChart'
import { formatAgeSimpleAbs } from '../../api/time'
import WithLoader from '../common/WithLoader'

export const PlaytimeCalendar = ({ account }) => {
	let { data, error, refetch } =
		useApiPath(`/account/${account}/playtime/day`, useCallback(
			rows => rows && rows.map(d => ({
				...d,
				day: new Date(d.day),
				playtime: +d.playtime,
			})), []))

	const weightMax = data && Math.max(60 * 1000, ...data.map(d => +d.playtime))
	const lightnessScale = scaleLinear({
		domain: [0, weightMax],
		range: [1, .3],
	})
	const hueScale = scaleLinear({
		domain: [0, weightMax],
		range: [3 / 6, 4 / 6],
	})

	return <WithLoader name='player activity' data={data} error={error} refetch={refetch}>
		<CalendarChart
			data={data}
			getDate={useCallback(d => d.day, [])}
			weekBegin={3}
			getLightness={d => lightnessScale(+d.playtime)}
			getHue={d => hueScale(+d.playtime)}
			getTooltip={({ day, playtime }) => <div>
				{formatAgeSimpleAbs(playtime)} on {new Date(day).toLocaleDateString()}
			</div>}
		/>
	</WithLoader>
}

export default PlaytimeCalendar
