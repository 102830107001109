import { dayMs, hourMs, minuteMs } from './time'
import { generateRandomString, getLocalStorageJson, onExpiration, dateOrNothing } from './util'

const DISCORD_OAUTH2_CLIENT_ID = '732779232719142934'
const DISCORD_OAUTH2_REDIRECT_URI = `${document.location.origin}/me`

const tokenChars = '1234567890qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM_-'

export class UserSession {
	/** @param {import('./Api.js').Api} api */
	constructor(api) {
		this.api = api
		this.session = {}
		this.sessionStatus = {}

		if (window.localStorage) {
			this.checkLocalStorageLogin()
			this.checkUrlParamsLogin()
			// refresh token on page load
			if (this.session.sessionToken) this.loginWithDiscord({ sessionToken: this.session.sessionToken })
			// use new session when logging in in other tab
			window.addEventListener('storage', evt => {
				if (evt.key === 'civ.now.session') this.checkLocalStorageLogin()
			})
		}
	}

	get sessionToken() { return this.session.sessionToken }
	get sessionTokenExpiration() { return this.session.sessionTokenExpiration }
	get discordUserData() { return this.session.discordUserData }
	get discordToken() { return this.session.discordToken }
	get discordTokenExpiration() { return this.session.discordTokenExpiration }

	checkLocalStorageLogin() {
		if (!window.localStorage) return
		try {
			const sessionFromLS = getLocalStorageJson('civ.now.session')
			let {
				sessionToken, sessionTokenExpiration,
				discordUserData,
				discordToken, discordTokenExpiration,
			} = sessionFromLS
			sessionTokenExpiration = +dateOrNothing(sessionTokenExpiration)
			discordTokenExpiration = +dateOrNothing(discordTokenExpiration)
			if (!sessionToken) {
				this.sessionStatus = {}
			} else if (sessionTokenExpiration > Date.now()) {
				this.session = { ...this.session, sessionToken, sessionTokenExpiration }
				this.sessionStatus = { success: `Browser` }
				// refresh session one day before it expires
				onExpiration(() => this.session.sessionTokenExpiration - dayMs, () => {
					this.loginWithDiscord({ sessionToken: this.session.sessionToken })
				})
				if (discordUserData) this.session.discordUserData = discordUserData
				if (discordTokenExpiration > Date.now()) {
					this.session = { ...this.session, discordToken, discordTokenExpiration }
					onExpiration(() => this.session.discordTokenExpiration, () => {
						delete this.session.discordTokenExpiration
						delete this.session.discordToken
					})
				}
				console.log('Logged in (LocalStorage)')
				this.api.emitChangeEvent('session')
			} else {
				console.error('LocalStorage session expired')
				this.sessionStatus = { error: `Session expired` }
				this.api.emitChangeEvent('session')
			}
		} catch (err) {
			console.error('While getting user login session from LocalStorage:', err)
		}
	}

	checkUrlParamsLogin() {
		if (!window.localStorage) return
		try {
			const urlParams = new URLSearchParams(document.location.search)
			if (urlParams.get('state') && urlParams.get('code')) {
				// we have a potential oauth code, but we need to verify that it was requested by us, to prevent CSRF attacks
				const { discordCsrfToken, discordCsrfTokenExpiration } = getLocalStorageJson('civ.now.discordCsrfToken')
				if (!discordCsrfTokenExpiration || discordCsrfTokenExpiration < Date.now()) {
					this.sessionStatus = { error: `CSRF token expired` }
				} else if (urlParams.get('state') !== discordCsrfToken) {
					this.sessionStatus = { error: `Invalid CSRF token` }
				} else {
					this.sessionStatus = { waiting: `API` }
					window.localStorage.removeItem('civ.now.discordCsrfToken')
					this.loginWithDiscord({ oauthCode: urlParams.get('code'), redirectUri: DISCORD_OAUTH2_REDIRECT_URI })
						.then(() => window.history.replaceState(null, null, document.location.href.split('?', 2)[0]))
				}
				this.api.emitChangeEvent('session')
			}
		} catch (err) {
			console.error('While checking OAuth2 login url params:', err)
		}
	}

	async loginWithDiscord(authArgs) {
		try {
			const apiResponse = await this.api.fetchApi('users/discord-oauth2', {}, authArgs)
			let {
				error,
				sessionToken,
				sessionTokenExpiration,
				discordUserData,
				discordToken,
				discordTokenExpiration,
			} = apiResponse
			sessionTokenExpiration = +dateOrNothing(sessionTokenExpiration)
			discordTokenExpiration = +dateOrNothing(discordTokenExpiration)
			if (error) {
				this.sessionStatus = { error: error }
				// keep old session data
			} else if (!sessionToken) {
				this.sessionStatus = { error: `Login denied by API` }
				// keep old session data
			} else {
				this.sessionStatus = { success: `API` }
				this.session = { ...this.session, sessionToken, sessionTokenExpiration }
				if (discordUserData) this.session.discordUserData = discordUserData
				if (discordToken) this.session = { ...this.session, discordToken, discordTokenExpiration }
				if (window.localStorage) window.localStorage.setItem('civ.now.session', JSON.stringify(this.session))
				// note that constructor() registered a LocalStorage change handler
				// that may trigger now and re-apply the session, see checkLocalStorageLogin()
				console.log('Logged in (API response)')
			}
			this.api.emitChangeEvent('session')
		} catch (err) {
			console.error('While fetching user session from api:', err)
		}
	}

	async logout() {
		await this.api.fetchApi('users/logout', {}, { sessionToken: this.sessionToken })
			.catch(console.error) // delete in browser regardless of success or error
		this.session = {}
		if (window.localStorage) window.localStorage.removeItem('civ.now.session')
		console.log('Logged out')
		this.api.emitChangeEvent('session')
	}

	_oauthLoginUrl = null

	prepareOAuthLoginUrl() {
		if (this._oauthLoginUrl) return this._oauthLoginUrl
		if (!window.localStorage) throw new Error(`Can't log in: LocalStorage not supported`)
		let { discordCsrfToken, discordCsrfTokenExpiration } = getLocalStorageJson('civ.now.discordCsrfToken')
		// preserve existing csrfToken if possible, so if another tab is open using that token it will still work
		const validForAtLeast10Min = discordCsrfTokenExpiration > Date.now() + 10 * minuteMs
		if (!validForAtLeast10Min) {
			discordCsrfToken = generateRandomString(32, tokenChars)
			discordCsrfTokenExpiration = Date.now() + hourMs
			window.localStorage.setItem('civ.now.discordCsrfToken', JSON.stringify({
				discordCsrfToken, discordCsrfTokenExpiration
			}))
		}
		return this._oauthLoginUrl = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_OAUTH2_CLIENT_ID}&redirect_uri=${encodeURIComponent(DISCORD_OAUTH2_REDIRECT_URI)}&response_type=code&scope=identify&prompt=none&state=${discordCsrfToken}`
	}
}

export default UserSession
