import React from 'react'

export const ExLink = ({ children, ...props }) => <a target="_blank" rel="noopener noreferrer" {...props}>{children}</a>

/** @param {{children: any}} props */
export const Muted = ({ children = [] }) => (
	<span style={{ opacity: .5 }}>
		{children}
	</span>
)

export const KeyValList = d => (
	<ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
		{Object.entries(d).map(([k, v]) =>
			<li key={k}>
				<span style={{ fontWeight: 'bold' }}>
					{`${k}:`}
				</span>
				{` ${v}`}
			</li>
		)}
	</ul>
)

export const splitOrEmptyArr = s => s ? s.split(' ') : []

export const cssFromHSL = (h, s, l = .5) => `hsl(${360 * h},${100 * s}%,${100 * l}%)`
