import React from 'react'
import ReactDOM from 'react-dom'
import {
	BrowserRouter as Router,
} from 'react-router-dom'
import Api from './api/Api'
import ApiContext from './components/ApiContext'
import AppShell from './components/AppShell'

import './index.scss'

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<ApiContext.Provider value={new Api(`https://vigil-api.duckdns.org/api`)}>
				<AppShell />
			</ApiContext.Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
)
