import React, { useCallback } from 'react'
import { scaleLinear } from '@vx/scale'

import { useApiPath } from '../ApiContext'
import HeatMap from '../charting/HeatMap'
import WithLoader from '../common/WithLoader'
import { weekdayNameFromNr, weekdayNamesBaseWed } from '../../api/time'
import { pluralize } from '../../api/util'

export const PlayerScheduleChart = ({ account }) => {
	let { data, error: dataError, refetch: refetchData } =
		useApiPath(`/account/${account}/logins/dayhour-weekday`, useCallback(
			rows => rows && rows.map(d => ({
				...d,
				weekdayName: weekdayNameFromNr(d.weekdayNr),
			})), []))

	const weightMax = data && Math.max(7, ...data.map(d => +d.logins))
	const lightnessScale = scaleLinear({
		domain: [0, weightMax],
		range: [1, .2],
	})
	const hueScale = scaleLinear({
		domain: [0, weightMax],
		range: [3 / 6, 4 / 6],
	})

	return <WithLoader name='player schedule' data={data} error={dataError} refetch={refetchData}>
		<HeatMap style={{ width: '100%' }}
			data={data}

			title='On how many days the player has logged in during each slot'

			labelX='Hour of the day (PST)'
			getX={useCallback(d => d.dayHour, [])}
			minX={0}
			maxX={23}
			intervalX={1}

			labelY='Day of the week'
			getY={useCallback(d => d.weekdayName, [])}
			categoriesY={weekdayNamesBaseWed}

			getLightness={d => lightnessScale(+d.logins)}
			getHue={d => hueScale(+d.logins)}

			getTooltip={({ weekdayName, dayHour, logins }) => <div>
				logged in on <strong>{logins}</strong> {pluralize('day', +logins)}
				{' '}
				between {dayHour} and {dayHour + 1} on {weekdayName}
			</div>}
		/>
	</WithLoader>
}

export default PlayerScheduleChart
