import React from 'react'

import { ExLink } from '../util'

export const AboutPage = () => (
	<div className='civnow-prose civnow-content-maxwidth'>
		<h1>About Civ Now</h1>
		<p>
			Civ Now takes the publicly available data from CivRealms' tablist and global chat,
			and lets anyone freely gather insights from it.
		</p>
		<p>
			For many years, only <ExLink href={urls.openSourcingAutism}>elite groups</ExLink> were able to analyze this data,
			even though it is publicly available to anyone logged into the game.
		</p>
		<p>
			Finally, for the first time since /u/remyroy's
			{' '}
			<ExLink href={urls.copsAnnouncement}>Civcraft Online Player Status (COPS)</ExLink> <ExLink href={urls.copsGone}>shut down</ExLink>,
			Civ Now makes this data freely available again
			with powerful search, aggregation, and annotation functionality.
		</p>
		<p>
			<ExLink href={urls.copsScreenshot}><img src={urls.copsScreenshot}
				alt="A screenshot of COPS by /u/remyroy." className='civnow-fullwidth-image' /></ExLink>
			A screenshot of <ExLink href={urls.copsAnnouncement}>COPS</ExLink> by /u/remyroy. Click to enlarge.
		</p>
		<hr />
		<p>
			<ExLink href={urls.discordInvite}>Contact Gjum</ExLink> for more info about Civ Now.
		</p>
	</div>
)

const urls = {
	openSourcingAutism: "https://old.reddit.com/r/civclassics/comments/htuwj8/opensourcing_autism_a_few_mechanics_and_ideas/",
	copsAnnouncement: "https://old.reddit.com/r/Civcraft/comments/13kwjm/introducing_the_cops_civcraft_online_player_status/",
	copsGone: "https://old.reddit.com/r/Civcraft/comments/157ynw/and_its_gone_cops_civcraft_online_player_status/",
	copsScreenshot: "https://i.imgur.com/SR2qII5.jpg",
	discordInvite: "https://discord.gg/9fWjvaj",
}

export default AboutPage
