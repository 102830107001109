import querystring from 'querystring'
import { minuteMs } from './time'
import UserSession from './UserSession'

export class Api {
	constructor(endpointUrl) {
		this.endpointUrl = endpointUrl

		this.session = new UserSession(this)
	}

	fetchApi(path, params, body) {
		path = String(path)
		if (!path.startsWith('/')) path = '/' + path
		let url = this.endpointUrl + path
		if (params && Object.keys(params).length) {
			url += '?' + querystring.stringify(params)
		}
		const method = body === undefined ? 'GET' : 'POST'
		if (typeof body !== 'string') body = JSON.stringify(body)
		const headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		}
		return fetch(url, { body, headers, method })
			.then(r => r.json())
	}

	pathCaches = {}

	fetchCached(path, bypassCache = false) {
		const cache = this.pathCaches[path] || (
			this.pathCaches[path] = new CachedApiPath(this, path))
		return cache.fetch(bypassCache)
	}

	changeHandlers = {}
	nextHandlerId = 0

	onChange(handler) {
		const hid = ++this.nextHandlerId
		this.changeHandlers[hid] = handler
		return () => delete this.changeHandlers[hid]
	}

	emitChangeEvent(evt) {
		for (const handler of Object.values(this.changeHandlers)) {
			try { handler(evt) } catch (err) { console.error(err) }
		}
	}
}

class CachedApiPath {
	responsePromise = null
	lastFetchTs = 0

	constructor(api, path, cacheTimeout = 5 * minuteMs) {
		this.api = api
		this.path = path
		this.cacheTimeout = cacheTimeout
	}

	fetch(bypassCache = false) {
		if (!this.responsePromise || bypassCache || Date.now() - this.lastFetchTs > this.cacheTimeout) {
			this.lastFetchTs = Date.now()
			this.responsePromise = new Promise((resolve, reject) =>
				this.api.fetchApi(this.path)
					.then(resolve, reject))
		}
		return this.responsePromise
	}
}

export default Api
