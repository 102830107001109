import React from 'react'
import PlayerCountCalendar from '../charts/PlayerCountCalendar'

export const LandingPage = () => (
	<div className='civnow-prose civnow-content-maxwidth'>
		<h1>Civ Now</h1>
		<p>
			Soon&trade;
		</p>
		<PlayerCountCalendar />
	</div>
)

export default LandingPage
