import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { useApiPath } from '../ApiContext'
import PlayerScheduleChart from '../charts/PlayerScheduleChart'
import PlaytimeCalendar from '../charts/PlaytimeCalendar'
import WithLoader from '../common/WithLoader'

import './PlayerInfoPage.scss'

export const PlayerInfoPage = () => {
	const { account } = useParams()

	const { data: accountInfo, error: fetchError, refetch: refetchAccount } =
		useApiPath(`account/${account}`, useCallback(data => {
			if (!data) return
			if (data.sessions) {
				data.sessions = data.sessions.map(row => ({
					...row,
					login: !row.login ? undefined : new Date(row.login),
					logout: !row.logout ? undefined : new Date(row.logout),
				}))
					.filter(d => !d.combatLogger)
			}
			if (data.votes) {
				data.votes = data.votes.map(
					row => ({ ...row, ts: new Date(row.ts) }))
			}
			if (data.firstJoinTs) {
				data.firstJoinTs = new Date(data.firstJoinTs)
			}
			return data
		}, []))

	return <div className='civnow-content-maxwidth'>
		<img alt={`Skin of ${account}`}
			src={`https://minotar.net/armor/body/${account}/128.png`}
			className='civnow-playerinfo-avatar'
		/>
		<h1>Player {account}</h1>
		<WithLoader name='player info' data={accountInfo} error={fetchError} refetch={refetchAccount}>
			{accountInfo && accountInfo.firstJoinTs &&
				<p>Joined CivRealms on {new Date(accountInfo.firstJoinTs).toLocaleString()}</p>
			}
			<PlaytimeCalendar account={account} />
			<PlayerScheduleChart account={account} />
		</WithLoader>
	</div>
}

export default PlayerInfoPage
